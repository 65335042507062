// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/bg_gold_card_faces_hanging_from_string.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-features {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
  }
  
  .feature-content-container {
    margin: 0 auto 2rem auto;
    max-width: 720px;
    text-align: left;
  }

  .purple-glass-container {
    padding: 1.5rem;
    border-radius: 1.5rem;
    background-image: linear-gradient(300deg, #994981cc, #5c0f80cc);
    box-shadow: 5px 5px 5px #000;
    box-shadow: 5px 5px 5px #000;
    backdrop-filter: blur(7px);
  }
  .purple-glass-container-2 {
    padding: 1.5rem;
    border-radius: 1.5rem;
    background-image: linear-gradient(300deg, #914d9e88, #2a5470a1);
    box-shadow: 5px 5px 5px #000;
    box-shadow: 5px 5px 5px #000;
    backdrop-filter: blur(7px);
  }

  .golden-glass-container {
    padding: 1.5rem;
    border-radius: 1.5rem;
    background-image: linear-gradient(300deg, #6b2e1b99, #be6e1ee0);
    box-shadow: 5px 5px 5px #000;
    box-shadow: 5px 5px 5px #000;
    backdrop-filter: blur(7px);
  }

  .p2{
    font-size: 19px;
  }`, "",{"version":3,"sources":["webpack://./src/components/features/Features.css"],"names":[],"mappings":"AAAA;IACI,yDAA6E;IAC7E,wBAAwB;IACxB,4BAA4B;IAC5B,6BAA6B;EAC/B;;EAEA;IACE,wBAAwB;IACxB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,+DAA+D;IAC/D,4BAA4B;IAC5B,4BAA4B;IAC5B,0BAA0B;EAC5B;EACA;IACE,eAAe;IACf,qBAAqB;IACrB,+DAA+D;IAC/D,4BAA4B;IAC5B,4BAA4B;IAC5B,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,+DAA+D;IAC/D,4BAA4B;IAC5B,4BAA4B;IAC5B,0BAA0B;EAC5B;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".section-features {\r\n    background-image: url(\"../images/bg_gold_card_faces_hanging_from_string.png\");\r\n    background-size: contain;\r\n    background-repeat: no-repeat;\r\n    background-position: top left;\r\n  }\r\n  \r\n  .feature-content-container {\r\n    margin: 0 auto 2rem auto;\r\n    max-width: 720px;\r\n    text-align: left;\r\n  }\r\n\r\n  .purple-glass-container {\r\n    padding: 1.5rem;\r\n    border-radius: 1.5rem;\r\n    background-image: linear-gradient(300deg, #994981cc, #5c0f80cc);\r\n    box-shadow: 5px 5px 5px #000;\r\n    box-shadow: 5px 5px 5px #000;\r\n    backdrop-filter: blur(7px);\r\n  }\r\n  .purple-glass-container-2 {\r\n    padding: 1.5rem;\r\n    border-radius: 1.5rem;\r\n    background-image: linear-gradient(300deg, #914d9e88, #2a5470a1);\r\n    box-shadow: 5px 5px 5px #000;\r\n    box-shadow: 5px 5px 5px #000;\r\n    backdrop-filter: blur(7px);\r\n  }\r\n\r\n  .golden-glass-container {\r\n    padding: 1.5rem;\r\n    border-radius: 1.5rem;\r\n    background-image: linear-gradient(300deg, #6b2e1b99, #be6e1ee0);\r\n    box-shadow: 5px 5px 5px #000;\r\n    box-shadow: 5px 5px 5px #000;\r\n    backdrop-filter: blur(7px);\r\n  }\r\n\r\n  .p2{\r\n    font-size: 19px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
