// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #000;
    color: #ff514f;
    padding: 2rem 0 2rem;
    margin-top: 1rem;
  }
  
  footer strong {
    color: yellow;
  }
  
  footer img {
    height: 3rem;
    margin: 1.5rem 0;
  }
  
  footer a {
    color: white;
  }
  
  footer svg.svg-inline--fa {
    font-size: 1.6rem;
    margin: 1.2rem 0.5rem 0 0;
  }
  
  footer svg.svg-inline--fa:hover {
    color: #1ebba3 !important;
  }
  
  hr.socket {
    width: 100%;
    border-top: 0.2rem solid #6666b7;
  }`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;IACd,oBAAoB;IACpB,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,gCAAgC;EAClC","sourcesContent":["footer {\r\n    background-color: #000;\r\n    color: #ff514f;\r\n    padding: 2rem 0 2rem;\r\n    margin-top: 1rem;\r\n  }\r\n  \r\n  footer strong {\r\n    color: yellow;\r\n  }\r\n  \r\n  footer img {\r\n    height: 3rem;\r\n    margin: 1.5rem 0;\r\n  }\r\n  \r\n  footer a {\r\n    color: white;\r\n  }\r\n  \r\n  footer svg.svg-inline--fa {\r\n    font-size: 1.6rem;\r\n    margin: 1.2rem 0.5rem 0 0;\r\n  }\r\n  \r\n  footer svg.svg-inline--fa:hover {\r\n    color: #1ebba3 !important;\r\n  }\r\n  \r\n  hr.socket {\r\n    width: 100%;\r\n    border-top: 0.2rem solid #6666b7;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
