// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background: radial-gradient(circle, rgb(11, 11, 20), rgb(29, 35, 55));    
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes glowinout-less {
    50% {
      filter: drop-shadow(1 0 13px yellow);
    }
  }
  
  @keyframes glowinout {
    50% {
      filter: drop-shadow(5px 5px 50px yellow);
    }
  }
  
  
  
  .glowing-image{
   
    animation: glowinout-less 1.6s linear infinite;
  }

  @media( min-width: 768px){
    .text-content{
      padding-left: 120px;
    }
  }

  @media(max-width: 767px){
    .text-content{
      padding-top: 50px;
    }
  }

  @media(max-width: 564px){
    .text-content{
      padding-top: 30px
    }
  }

  





`, "",{"version":3,"sources":["webpack://./src/components/gamingdetails/GamesDetails.css"],"names":[],"mappings":"AAAA;IACI,qEAAqE;IACrE,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE;MACE,oCAAoC;IACtC;EACF;;EAEA;IACE;MACE,wCAAwC;IAC1C;EACF;;;;EAIA;;IAEE,8CAA8C;EAChD;;EAEA;IACE;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE;MACE,iBAAiB;IACnB;EACF;;EAEA;IACE;MACE;IACF;EACF","sourcesContent":["body {\n    background: radial-gradient(circle, rgb(11, 11, 20), rgb(29, 35, 55));    \n    min-height: 100vh;\n    margin: 0;\n    padding: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  @keyframes glowinout-less {\n    50% {\n      filter: drop-shadow(1 0 13px yellow);\n    }\n  }\n  \n  @keyframes glowinout {\n    50% {\n      filter: drop-shadow(5px 5px 50px yellow);\n    }\n  }\n  \n  \n  \n  .glowing-image{\n   \n    animation: glowinout-less 1.6s linear infinite;\n  }\n\n  @media( min-width: 768px){\n    .text-content{\n      padding-left: 120px;\n    }\n  }\n\n  @media(max-width: 767px){\n    .text-content{\n      padding-top: 50px;\n    }\n  }\n\n  @media(max-width: 564px){\n    .text-content{\n      padding-top: 30px\n    }\n  }\n\n  \n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
