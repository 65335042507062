// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/bg_gold_card_faces_hanging_from_string.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-expertise {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
  }
  
  .expertise-list-outer-container {
    max-width: 768px;
    margin: 3rem auto;
  }

  .golden-glass-container {
    padding: 1.5rem;
    border-radius: 1.5rem;
    background-image: linear-gradient(300deg, #6b2e1b99, #be6e1ee0);
    box-shadow: 5px 5px 5px #000;
    box-shadow: 5px 5px 5px #000;
    backdrop-filter: blur(7px);
  }

  .services-container{
    padding:0.5rem;
  }

  .services-team-container{
    background-image: linear-gradient(300deg, #82598c99, #852195e0);
    box-shadow: 5px 5px 5px #000;
    box-shadow: 5px 5px 5px #000;
    backdrop-filter: blur(7px);
  }`, "",{"version":3,"sources":["webpack://./src/components/expertise/Expertise.css"],"names":[],"mappings":"AAAA;IACI,yDAA6E;IAC7E,sBAAsB;IACtB,8BAA8B;IAC9B,4BAA4B;EAC9B;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,+DAA+D;IAC/D,4BAA4B;IAC5B,4BAA4B;IAC5B,0BAA0B;EAC5B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,+DAA+D;IAC/D,4BAA4B;IAC5B,4BAA4B;IAC5B,0BAA0B;EAC5B","sourcesContent":[".section-expertise {\r\n    background-image: url(\"../images/bg_gold_card_faces_hanging_from_string.png\");\r\n    background-size: cover;\r\n    background-position: top right;\r\n    background-repeat: no-repeat;\r\n  }\r\n  \r\n  .expertise-list-outer-container {\r\n    max-width: 768px;\r\n    margin: 3rem auto;\r\n  }\r\n\r\n  .golden-glass-container {\r\n    padding: 1.5rem;\r\n    border-radius: 1.5rem;\r\n    background-image: linear-gradient(300deg, #6b2e1b99, #be6e1ee0);\r\n    box-shadow: 5px 5px 5px #000;\r\n    box-shadow: 5px 5px 5px #000;\r\n    backdrop-filter: blur(7px);\r\n  }\r\n\r\n  .services-container{\r\n    padding:0.5rem;\r\n  }\r\n\r\n  .services-team-container{\r\n    background-image: linear-gradient(300deg, #82598c99, #852195e0);\r\n    box-shadow: 5px 5px 5px #000;\r\n    box-shadow: 5px 5px 5px #000;\r\n    backdrop-filter: blur(7px);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
