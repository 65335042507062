import "./App.css";
import NavBar from "./components/navbar/Navbar";
import Banner from "./components/banner/Banner";
import About from "./components/about/About";
import Enquiry from "./components/enquiry/Enquiry";
import Features from "./components/features/Features";
import Expertise from "./components/expertise/Expertise";
import Footer from "./components/footer/Footer";
import Games from "./components/gaming/Games";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Casino from "./components/gamingdetails/Casino";
import NMSRummy from "./components/gamingdetails/NMSRummy";
import Poker from "./components/gamingdetails/Poker";
import JollyCricket from "./components/gamingdetails/JollyCricket";
import BubbleShooter from "./components/gamingdetails/BubbleShooter";
import Carrom from "./components/gamingdetails/Carrom";
import Daipass from "./components/gamingdetails/Daipass";
import Tambola from "./components/gamingdetails/Tambola";
import QuizGame from "./components/gamingdetails/QuizGame";
import Teenpatti from "./components/gamingdetails/Teenpatti";
import FunTargetTimer from "./components/gamingdetails/FunTargetTimer";
import BigExchangeGame from "./components/gamingdetails/BigExchangeGame";
import LudoFantoosh from "./components/gamingdetails/LudoFantoosh";
import RouletteGame from "./components/gamingdetails/RouletteGame";
import Cards from "./components/gamingdetails/Cards";
import Chessknight from "./components/gamingdetails/Chessknight";
import Detonix from "./components/gamingdetails/Detonix";
import InfernoBrawl from "./components/gamingdetails/InfernoBrawl";
import JollyFootball from "./components/gamingdetails/JollyFootball";
import JollyHockey from "./components/gamingdetails/JollyHockey";
import Knight from "./components/gamingdetails/Knight";
import NinjaKill from "./components/gamingdetails/NinjaKill";
import UPBalls from "./components/gamingdetails/UPBalls";
import Venturex from "./components/gamingdetails/Venturex";
import PrivacyPolicy from "./components/privacypolicy/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <NavBar />
              <section id="banner">
                <Banner />
              </section>
              <section id="about">
                <About />
              </section>
              <section id="features">
                <Features />
              </section>
              <section id="expertise">
                <Expertise />
              </section>
              <section id="games">
                <Games />
              </section>
              <section id="enquiry">
                <Enquiry />
              </section>
              <Footer />
            </>
          }
        />
        <Route path="/casino" element={<Casino />} />
        <Route path="/rummy" element={<NMSRummy />} />
        <Route path="/poker" element={<Poker />} />
        <Route path="/carrom" element={<Carrom />} />
        <Route path="/daipass" element={<Daipass />} />
        <Route path="/tambola" element={<Tambola />} />
        <Route path = '/quiz' element = {<QuizGame />} />
        <Route path = '/teenpatti' element = {<Teenpatti />} />
        <Route path = '/funtarget' element = {<FunTargetTimer />} />
        <Route path = '/bigwincasino' element = {<BigExchangeGame />} />
        <Route path = '/ludogame' element = {<LudoFantoosh />} />
        <Route path = '/roulette' element = {<RouletteGame />} />
        <Route path = '/Cards' element = {<Cards/>} />
        <Route path = '/Chessknight' element = {<Chessknight/>} />
        <Route path = '/Detonix' element = {<Detonix/>} />
        <Route path = '/InfernoBrawl' element = {<InfernoBrawl/>} />
        <Route path = '/jollyCricket' element = {<JollyCricket/>} />
        <Route path = '/JollyFootball' element = {<JollyFootball/>} />
        <Route path = '/JollyHockey' element = {<JollyHockey/>} />
        <Route path = '/Knight' element = {<Knight/>} />
        <Route path = '/Venturex' element = {<Venturex/>} />
        <Route path = '/NinjaKill' element = {<NinjaKill/>} />
        <Route path = '/UPBalls' element = {<UPBalls/>} />
        <Route path = '/BubbleShooter' element = {<BubbleShooter/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        




         

      </Routes>
    </Router>
  );
}

export default App;
