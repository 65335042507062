// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  background-color: #000;

  width: 100vw;
}


body {
  overflow-x: hidden;
}

p {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px){
  body{
    overflow-x: hidden;
  }
}

@media (max-width: 564px){
  body{
    overflow-x: hidden;
  }
}











  


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,WAAW;EACX,sBAAsB;;EAEtB,YAAY;AACd;;;AAGA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE;IACE,sBAAsB;IACtB,UAAU;EACZ;EACA;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["body {\r\n  font-family: \"Source Sans Pro\", sans-serif;\r\n  color: #fff;\r\n  background-color: #000;\r\n\r\n  width: 100vw;\r\n}\r\n\r\n\r\nbody {\r\n  overflow-x: hidden;\r\n}\r\n\r\np {\r\n  padding-top: 0.5rem;\r\n  padding-bottom: 1.5rem;\r\n}\r\n\r\n@keyframes tracking-in-expand {\r\n  0% {\r\n    letter-spacing: -0.5em;\r\n    opacity: 0;\r\n  }\r\n  40% {\r\n    opacity: 0.6;\r\n  }\r\n  100% {\r\n    opacity: 1;\r\n  }\r\n}\r\n\r\n@media (max-width: 768px){\r\n  body{\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n@media (max-width: 564px){\r\n  body{\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n  \r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
