import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '2rem', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ fontSize: '2rem' }}>Privacy Policy</h1>
      <p>
        At NMS Games Private Limited, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information.
      </p>

      <h2 style={{ fontSize: '1.5rem' }}>Information Collection</h2>
      <p>We collect the following personal information from our clients:</p>
      <ul>
        <li>Mobile Number</li>
        <li>Email Address</li>
      </ul>

      <h2 style={{ fontSize: '1.5rem' }}>Purpose of Collection</h2>
      <p>The information we collect is solely used to:</p>
      <ul>
        <li>Communicate with you to understand your requirements and provide our services.</li>
      </ul>

      <h2 style={{ fontSize: '1.5rem' }}>Use of Information</h2>
      <p>
        We do not use your personal information for any purposes other than those stated above. We do not sell, rent, or share your personal information with third parties.
      </p>

      <h2 style={{ fontSize: '1.5rem' }}>Data Security</h2>
      <p>
        We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
      </p>

      <h2 style={{ fontSize: '1.5rem' }}>Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal information we hold about you.</li>
        <li>Request correction of any inaccurate information.</li>
        <li>Request deletion of your personal information.</li>
      </ul>

      <h2 style={{ fontSize: '1.5rem' }}>Contact Us</h2>
      <p>
        If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at{' '}
        <a href="mailto:contact@nmsgames.in" style={{ color: '#007bff', textDecoration: 'underline' }}>
          contact@nmsgames.in
        </a>.
      </p>

      <h2 style={{ fontSize: '1.5rem' }}>Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
